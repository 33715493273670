import {
  TeliaButton,
  TeliaHeading,
  TeliaRow,
  TeliaCol,
  TeliaLink,
  TeliaP,
} from '@teliads/components/react';
import { navigate, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ContentfulRichText from '../components/ContentfulRichText';
import { Layout, Grid } from '../components/Layout';
import { useModalState } from '../context/ModalContext';
import { useStaticTranslations } from '../hooks/useStaticTranslations';
import {
  ContentfulGuideEntity,
  ContentfulStaticTranslations,
} from '../models/ContentfulTypes';
import '../styles/step.scss';

interface Props extends PageProps {
  pageContext: {
    entity: ContentfulGuideEntity;
    stepNumber: number;
    subStepNumber: number;
    steps: { entity: ContentfulGuideEntity; url: string }[];
    subSteps?: { entity: ContentfulGuideEntity; url: string }[];
    nextPageUrl: string;
    totalAmountOfSteps: number;
    stepBeginning: number;
    contentfulId: string;
    deviceSlug: string;
    language: string;
  };
}

export default ({ pageContext }: Props) => {
  const {
    entity,
    stepNumber,
    nextPageUrl,
    totalAmountOfSteps,
    stepBeginning,
    contentfulId,
    steps,
    subSteps,
    subStepNumber,
    deviceSlug,
    language,
  } = pageContext;
  const [showAllSteps, toggleStepsMenu] = useState(false);
  const { toggleModal, setEntity, entity: modalEntity } = useModalState();

  useEffect(() => {
    if (entity.helpEntity) {
      setEntity(entity.helpEntity);
    }
  }, []);

  const translation = useStaticTranslations();
  const showAllStepsTranslation = translation[language].nodes?.find(
    node => node.contentful_id === ContentfulStaticTranslations.ShowAllSteps
  );
  const stepTranslation = translation[language].nodes?.find(
    node => node.contentful_id === ContentfulStaticTranslations.step
  );

  // When one sub step is selected, we should not show the rest of the sub steps, they are supposed to be show only based on selection in the entity level
  // Example could be /fwa-telia-5g-antenna-set-up/set-up-telia-5-g-antenna/select-installation/
  // The Selection installation will have 3 sub steps and only 1 is supposed to be shown and after than we need to jump into main step

  const onlyNeededSubSteps = subSteps?.filter(
    subStep => subStep.entity.contentful_id === entity.contentful_id
  );

  return (
    <Layout
      withSteps
      contentfulId={contentfulId}
      deviceSlug={deviceSlug}
      title={entity.title}
    >
      <Grid>
        <TeliaRow className="padding--vertical column">
          <span className="telia-heading--step">
            {stepTranslation?.title} {stepNumber}
            {subStepNumber ? `.${subStepNumber}` : ''}
          </span>
          <TeliaHeading variant="display-100" tag="h2">
            {entity.title}
          </TeliaHeading>
        </TeliaRow>
        <TeliaRow className="column gap center padding--vertical">
          {entity.entityElements?.map((element, index) => {
            return (
              <article
                className="step__item"
                key={element.contentful_id + index}
              >
                <div className="step__item--title">
                  <TeliaHeading tag={'h4'} variant={'title-100'}>
                    {element.title || 'No Title'}
                  </TeliaHeading>
                  {element.description?.raw && (
                    <ContentfulRichText raw={element.description.raw} />
                  )}
                </div>
                <div className="step__item--footer">
                  {element.animation?.file?.url ? (
                    <video autoPlay loop preload="auto" playsInline>
                      <source
                        src={element.animation?.file?.url}
                        type="video/mp4"
                      />
                      <img src={element.mediaAsset?.file?.url} alt="" />
                    </video>
                  ) : (
                    <img src={element.mediaAsset?.file?.url} alt="" />
                  )}
                </div>
              </article>
            );
          })}
        </TeliaRow>
        {entity.infoMessage && (
          <TeliaRow className="column padding--vertical padding--horizontal message">
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C23.9926 5.37566 18.6243 0.00741698 12 0ZM12.9233 9.70274V17.0032C12.9237 17.2511 12.8742 17.4965 12.7776 17.7249C12.6986 17.9296 12.5753 18.1143 12.4167 18.2659C12.3049 18.3816 12.1537 18.4509 11.9932 18.4602C11.7616 18.4602 11.5303 18.2846 11.2991 17.9333C11.1736 17.7785 11.0964 17.5901 11.0771 17.3917V10.0913C11.0771 9.88798 11.2183 9.69371 11.5007 9.50848C11.7693 9.32778 12.0857 9.23123 12.4094 9.23117C12.7516 9.23118 12.9229 9.38837 12.9233 9.70274ZM13.035 6.06631C13.0356 6.34046 12.9812 6.61195 12.8751 6.86474C12.7867 7.09305 12.6494 7.29924 12.4728 7.46879C12.3428 7.60323 12.1663 7.68288 11.9795 7.69142C11.7388 7.69142 11.4889 7.50156 11.2297 7.12185C11.0881 6.94045 10.9971 6.72484 10.9658 6.49689C10.9658 6.26578 11.1233 6.04823 11.4382 5.84425C11.7387 5.64485 12.0913 5.53852 12.4519 5.53857C12.8407 5.53857 13.035 5.71449 13.035 6.06631Z"
                  fill="#0099FF"
                />
              </svg>
            </span>
            <ContentfulRichText raw={entity.infoMessage.raw} />
          </TeliaRow>
        )}
        {entity.successMessage && (
          <TeliaRow className="column padding--vertical padding--horizontal message message--success">
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill="#00A552"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.0798 9.13967L10.6101 16.6094C10.0894 17.1301 9.24521 17.1301 8.72451 16.6094L5.72451 13.6094C5.20381 13.0887 5.20381 12.2445 5.72451 11.7238C6.24521 11.2031 7.08943 11.2031 7.61013 11.7238L9.66732 13.781L16.1942 7.25405C16.7149 6.73335 17.5592 6.73335 18.0798 7.25405C18.6005 7.77475 18.6005 8.61897 18.0798 9.13967Z"
                  fill="white"
                />
              </svg>
            </span>
            <ContentfulRichText raw={entity.successMessage.raw} />
          </TeliaRow>
        )}
        {entity.errorMessage && (
          <TeliaRow className="column padding--vertical padding--horizontal message message--error">
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill="#E4175C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.4796 14.6347C11.0876 14.6347 10.8822 14.448 10.8636 14.056L10.4902 6.83204C10.4902 6.1787 11.5542 5.48804 12.5809 5.48804C13.1222 5.48804 13.3836 5.7307 13.3836 6.2347L13.0849 13.5894C13.0662 14.112 12.2822 14.6347 11.4796 14.6347ZM10.5089 17.0987C10.5089 17.5654 11.1996 18.7787 11.9276 18.7787C12.1702 18.7787 12.3942 18.6667 12.6182 18.4614C13.0662 18.0507 13.4209 17.2667 13.4209 16.5014C13.4209 15.9974 13.1409 15.7547 12.5996 15.7547C11.5729 15.7547 10.5089 16.4454 10.5089 17.0987Z"
                  fill="white"
                />
              </svg>
            </span>
            <ContentfulRichText raw={entity.errorMessage.raw} />
          </TeliaRow>
        )}
        <TeliaRow className="column gap center padding--vertical">
          <TeliaButton variant="primary" onClick={() => navigate(nextPageUrl)}>
            {entity.navigationButtonText?.replace(
              '#',
              subStepNumber &&
                onlyNeededSubSteps &&
                subStepNumber < onlyNeededSubSteps.length
                ? `${stepNumber}.${subStepNumber + 1}`
                : `${stepNumber + 1}`
            ) || 'Next'}
          </TeliaButton>
        </TeliaRow>
        {modalEntity && entity.helpEntity && (
          <TeliaRow className="column center padding--vertical">
            {entity.helpButtonText && (
              <TeliaP>{entity.helpEntity?.title}</TeliaP>
            )}
            <TeliaButton variant="text" onClick={toggleModal}>
              {entity.helpButtonText || entity.helpEntity?.title || 'Help me!'}
            </TeliaButton>
          </TeliaRow>
        )}
      </Grid>
      <Grid className="step-menu">
        <TeliaRow className="step-menu--header">
          <TeliaP>
            {stepNumber}
            {subStepNumber ? `.${subStepNumber}` : ''}/{totalAmountOfSteps}
          </TeliaP>
          <TeliaButton
            variant="text"
            onClick={() => {
              toggleStepsMenu(!showAllSteps);
            }}
          >
            {showAllStepsTranslation?.title}
          </TeliaButton>
        </TeliaRow>
        {showAllSteps && (
          <TeliaRow className="step-menu--items column">
            {steps.map((step, index) => {
              let isDone = true;
              const isCurrentStep = index + 1 === stepNumber;

              if (stepBeginning + index >= stepNumber) {
                isDone = false;
              }

              const isDoneClassName = isDone ? 'step--done' : '';
              const currentStepClassName = isCurrentStep ? 'current' : '';

              if (onlyNeededSubSteps && index + 1 === stepNumber) {
                return (
                  <>
                    <StepMenuItem
                      url={step.url}
                      isDone={isDone}
                      className={`${currentStepClassName} ${isDoneClassName} no-border`}
                      stepNumber={stepBeginning + index}
                      title={step.entity.title}
                      key={`step-menu-${step.entity.contentful_id}`}
                    />
                    {onlyNeededSubSteps.map((subStep, subStepIndex) => {
                      let isSubStepDone = false;
                      const isCurrentSubStep =
                        subStepIndex + 1 === subStepNumber;

                      if (subStepIndex + 1 < subStepNumber) {
                        isDone = true;
                      }

                      const isDoneClassName = isSubStepDone ? 'step--done' : '';
                      const currentStepClassName = isCurrentSubStep
                        ? 'current'
                        : '';
                      return (
                        <StepMenuItem
                          url={subStep.url}
                          isDone={isSubStepDone}
                          className={`${currentStepClassName} ${isDoneClassName}`}
                          stepNumber={stepBeginning + index}
                          subStepNumber={subStepNumber}
                          title={subStep.entity.title}
                          key={`substep-menu-${subStep.entity.contentful_id}`}
                        />
                      );
                    })}
                  </>
                );
              }

              return (
                <StepMenuItem
                  url={step.url}
                  isDone={isDone}
                  className={`${currentStepClassName} ${isDoneClassName}`}
                  stepNumber={stepBeginning + index}
                  title={step.entity.title}
                  key={`step-menu-${step.entity.contentful_id}`}
                />
              );
            })}
          </TeliaRow>
        )}
      </Grid>
    </Layout>
  );
};

const StepMenuItem = ({
  url,
  isDone,
  className,
  stepNumber,
  subStepNumber,
  title,
}: {
  url: string;
  className: string;
  stepNumber: number;
  title: string;
  isDone: boolean;
  subStepNumber?: number;
}) => (
  <TeliaLink silent href={url}>
    <div className={`step-menu--item padding--vertical ${className}`}>
      {!isDone ? (
        <TeliaP>
          {stepNumber}
          {subStepNumber ? `.${subStepNumber}` : ''}
        </TeliaP>
      ) : (
        <svg
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.505 1.47932L6.66371 11.6441C6.39479 11.9926 5.98892 12.2085 5.54965 12.2367C5.51676 12.2388 5.48417 12.24 5.45157 12.24C5.04559 12.2399 4.65627 12.0786 4.3692 11.7915L0.269021 7.69133C0.0370043 7.45931 -0.0536086 7.12114 0.0313155 6.8042C0.11624 6.48726 0.363799 6.2397 0.68074 6.15477C0.997681 6.06985 1.33585 6.16046 1.56787 6.39248L5.42197 10.2466L13.0506 0.357493C13.3604 -0.0441096 13.9371 -0.11854 14.3387 0.191249C14.7403 0.501037 14.8147 1.07773 14.505 1.47934V1.47932Z"
            fill="#30B673"
          />
        </svg>
      )}
      <TeliaP>{title}</TeliaP>
    </div>
  </TeliaLink>
);
